import { createApp } from 'vue'
import store from '@/store'
import App from './App'
import router from "@/router/router";
import VueLoading from 'vue-loading-overlay';

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import '@/assets/css/styles.css';
import 'swiper/swiper-bundle.css';

import 'vue-loading-overlay/dist/vue-loading.css';

import { getCookie } from "@/services/cookies";

const app = createApp(App)
app.mixin({
  methods: {
    image_url: function (images, size) {
      return this.first_image_url(((images || [])[0] || null), size)
    },
    first_image_url: function (image, size) {
      if(image){
        console.log(image);
        if(size){
          return this.$store.getters.MAIN_URL + image.assets[size];
        }
        return this.$store.getters.MAIN_URL + '/v1/attachment/download/' + image.attachment;
      }
      return require('@/assets/img/not_found.jpg');
    },
    start_end_date: function (date1, date2){
      console.log(date1);
      let date_start_arr = date1.split('-');
      let date_end_arr = date2.split('-');
      return date_start_arr[2] + " — " + date_end_arr[2] + " " + this.months[parseInt(date_end_arr[1]) - 1];
    },
    check_auth: function (){
      if (!getCookie('USER_ID') || !getCookie('AUTHORIZATION')) {
        this.$router.push('/');
      }
    }
  },
})

app.config.globalProperties.months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

app.use(router).use(store).use(VueLoading).mount("#app");
