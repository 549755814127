<template>
  <div class="mini-header" v-if="$route.fullPath !== '/' && !$route.fullPath.includes('search') && !$route.fullPath.includes('event')  && !$route.fullPath.includes('ambasador')">
    <nav-header></nav-header>
  </div>
</template>

<script >
  import NavHeader from '@/pages/layout/nav'
  export default {
    components: { NavHeader },
  }
</script>


<style scoped>
  .navbar{
    background-color: #5362f6 !important;
    padding-top: 10px;
  }
  .nav-link{
    font-size: 12px;
    font-weight: 600;
    color:white !important;
  }
  .nav-link:hover{
    color: #0a58ca !important;
  }
  .mini-header{
    background-color: #5362f6;
    height: 120px;
  }
  .navbar-brand img{
    width: 160px;
  }
  .nav-link.active {
    color: #0d0e0f !important;
  }
</style>