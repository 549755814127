import home from "@/pages/home"
import search from "@/pages/search"
import about from "@/pages/about"
import help from "@/pages/help"
import login from "@/pages/login"
import reg from "@/pages/reg"
import events from "@/pages/events"
import ambasador from "@/pages/ambasador"
import publications from "@/pages/publications"
import user from "@/pages/user"
import profile from "@/pages/user/profile"
import recovery from "@/pages/user/recovery"
import { createRouter, createWebHistory } from "vue-router"
import { setCookie } from "@/services/cookies";
import store from '@/store'

const routes = [
  {
    path: '/',
    component: home,
    children: [
      {
        name: 'recovery_password',
        path: 'recovery/:token',
        beforeEnter: (to, from, next) => {
          //store.dispatch('showDialog', {show: true, type: 'recovery_password'});
          next({path: '/recovery', query: { token: to.params.token } });
        }
      }
    ]
  },
  {
    path: '/search',
    name: 'search',
    component: search
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/help',
    name: 'help',
    component: help
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/reg',
    name: 'reg',
    component: reg
  },
  {
    path: '/recovery',
    name: 'recovery',
    component: recovery
  },
  {
    path: '/event/:id',
    name: 'event',
    component: events,
  },
  {
    path: '/ambasador/:id',
    name: 'ambasador',
    component: ambasador,
  },
  {
    path: '/publication/:id',
    name: 'publication',
    component: publications,
  },
  {
    path: '/user',
    name: 'user',
    component: user,
    children: [
      {
        name: 'profile',
        path: 'profile',
        component: profile
      }
    ]
  },
  {
    path: '/token/:token/:userId',
    name: 'token',
    beforeEnter: (to, from, next) => {
      if (to.params.token && to.params.userId) {
        setCookie('AUTHORIZATION', to.params.token, {expires: 3600 * 24 * 10, path: '/'});
        setCookie('USER_ID', to.params.userId, {expires: 3600 * 24 * 10, path: '/'});
        store.dispatch('CHECK_LOGIN');
        next({path: '/'});
      }
    },
  },
]

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL)
})

export default router;