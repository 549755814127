<template>
  <div class="icon">
    <a href='/login/vk'>
      <img src="@/assets/img/vk.svg" title='vk' width="28">
      <!--<img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOCIgaGVpZ2h0PSIyOCIgdmlld0JveD0iMCAwIDI4IDI4Ij4KICAgIDxwYXRoIGZpbGw9IiMwREFBRkUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTIwLjcxIDE2LjE4NXMxLjUxMiAxLjQ5NSAxLjg4NSAyLjE4NmEuMjIuMjIgMCAwIDEgLjAyMS4wMzVjLjE1LjI1NS4xODkuNDU0LjExMi42LS4xMjYuMjQ1LS41NTIuMzY3LS42OTkuMzc4aC0yLjY3NGMtLjE4NSAwLS41NzMtLjA1LTEuMDQ0LS4zNzQtLjM2NC0uMjUxLS43Mi0uNjY3LTEuMDY1LTEuMDcyLS41Mi0uNjA0LS45NjgtMS4xMjQtMS40MjItMS4xMjRhLjU0NC41NDQgMCAwIDAtLjE2Ny4wMjdjLS4zNDMuMTEyLS43ODMuNTk4LS43ODMgMS45IDAgLjQwNS0uMzIuNjQtLjU0OC42NGgtMS4yMjVjLS40MTYgMC0yLjU5MS0uMTQ4LTQuNTE2LTIuMTc2LTIuMzU3LTIuNDg3LTQuNDc2LTcuNDczLTQuNDk0LTcuNTE5LS4xMzItLjMyLjE0My0uNDk1LjQ0NC0uNDk1aDIuN2MuMzU5IDAgLjQ3Ny4yMi41NTguNDEyLjA5OC4yMjcuNDUgMS4xMjggMS4wMyAyLjE0Ljk0IDEuNjUyIDEuNTE1IDIuMzIyIDEuOTc2IDIuMzIyYS41NjcuNTY3IDAgMCAwIC4yNDgtLjA2MmMuNjA0LS4zMzYuNDktMi40ODMuNDY1LTIuOTMgMC0uMDg0LS4wMDQtLjk2LS4zMTEtMS4zODMtLjIyLS4zMDQtLjU5Ny0uNDItLjgyNC0uNDY0YS45OTIuOTkyIDAgMCAxIC4zNTYtLjNjLjQxMi0uMjA3IDEuMTYtLjIzOCAxLjktLjIzOGguNDEyYy44MDMuMDEgMS4wMDkuMDYzIDEuMjk5LjEzNi41ODYuMTQuNi41Mi41NDggMS44MmEyNi41IDI2LjUgMCAwIDAtLjAzMiAxLjI3N2MwIC4xMDktLjAwNi4yMi0uMDA2LjM0My0uMDE4LjY2LS4wMzkgMS40MS40MjkgMS43MjEuMDYuMDM4LjEzLjA2LjIwMy4wNi4xNiAwIC42NSAwIDEuOTczLTIuMjdhMTcuMjg1IDE3LjI4NSAwIDAgMCAxLjA2MS0yLjI3Yy4wMjgtLjA0NS4xMDUtLjE4OS4yLS4yNDVhLjQ4Ni40ODYgMCAwIDEgLjIyLS4wNTJoMy4xNzNjLjM0NiAwIC41ODMuMDUyLjYyOS4xODUuMDc3LjIxMy0uMDE0Ljg2LTEuNDYzIDIuODIybC0uNjQ2Ljg1MmMtMS4zMTcgMS43MjUtMS4zMTcgMS44MTIuMDc3IDMuMTE4TTAgMTRjMCA3LjczMSA2LjI2OSAxNCAxNCAxNHMxNC02LjI2OSAxNC0xNFMyMS43MzEgMCAxNCAwIDAgNi4yNjkgMCAxNHoiLz4KPC9zdmc+Cg==" title='vkontakte' width="28" />-->
    </a>
    <a href='/login/facebook'>
      <img src="@/assets/img/facebook.svg" title='facebook' width="28">
      <!--<img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOCIgaGVpZ2h0PSIyOCIgdmlld0JveD0iMCAwIDI4IDI4Ij4KICAgIDxwYXRoIGZpbGw9IiMwREFBRkUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTE0IDBDNi4yNjggMCAwIDYuMjY4IDAgMTRzNi4yNjggMTQgMTQgMTQgMTQtNi4yNjggMTQtMTRTMjEuNzMyIDAgMTQgMHptNC40MjEgOC4xMDVoLTIuMjFjLTEuMTQ0IDAtMS40NzQuNjc4LTEuNDc0IDEuNDc0djEuNDc0aDMuNjg0TDE3Ljk3NiAxNGgtMy4yNGwuMDAzIDguODQyaC0zLjY4NlYxNEg5LjU3OXYtMi45NDdoMS40NzRWOS41NzljMC0yLjQ5IDEuNDg0LTQuNDIxIDQuNDItNC40MjFoMi45NDh2Mi45NDd6Ii8+Cjwvc3ZnPgo=" title='facebook' width="28" />-->
    </a>
    <a href='/login/google'>
      <img src="@/assets/img/google.svg" title='google+' width="28">
      <!--<img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyI+DQo8cGF0aCBzdHlsZT0iZmlsbDojMERBQUZFOyIgZD0iTTI1NiwwQzExNC42MTUsMCwwLDExNC42MTUsMCwyNTZzMTE0LjYxNSwyNTYsMjU2LDI1NnMyNTYtMTE0LjYxNSwyNTYtMjU2UzM5Ny4zODUsMCwyNTYsMHoiLz4NCjxwYXRoIHN0eWxlPSJmaWxsOiMwREFBRkU7IiBkPSJNMjU2LDQ4NS45ODRjLTEyNi44MTQsMC0yMjkuOTg0LTEwMy4xNy0yMjkuOTg0LTIyOS45ODRTMTI5LjE4NiwyNi4wMTYsMjU2LDI2LjAxNg0KCWMxMjYuODEyLDAsMjI5Ljk4NCwxMDMuMTcsMjI5Ljk4NCwyMjkuOTg0UzM4Mi44MTIsNDg1Ljk4NCwyNTYsNDg1Ljk4NHoiLz4NCjxnPg0KCTxwb2x5Z29uIHN0eWxlPSJmaWxsOiNGMkYyRjI7IiBwb2ludHM9IjQ3Mi4yMDcsMjM3Ljc4NyA0NzIuMjA3LDI3NC4yMTMgNDMxLjQ5NywyNzQuMjEzIDQzMS40OTcsMzE0LjkyMyAzOTUuMDcxLDMxNC45MjMNCgkJMzk1LjA3MSwyNzQuMjEzIDM1NC4zNzUsMjc0LjIxMyAzNTQuMzc1LDIzNy43ODcgMzk1LjA3MSwyMzcuNzg3IDM5NS4wNzEsMTk3LjA3NyA0MzEuNDk3LDE5Ny4wNzcgNDMxLjQ5NywyMzcuNzg3IAkiLz4NCgk8cGF0aCBzdHlsZT0iZmlsbDojRjJGMkYyOyIgZD0iTTMxMS45NjYsMjMxLjQ0NmMxLjUxNCw3Ljk1NiwyLjMsMTYuMTcsMi4zLDI0LjU1NGMwLDkuNDEzLTAuOTg2LDE4LjU4My0yLjg3MSwyNy40NDENCgkJYy0xMi42MTMsNTkuNDA4LTY1LjM3OSwxMDMuOTc1LTEyOC41NDQsMTAzLjk3NWMtNTAuMDUxLDAtOTMuNTYyLTI3Ljk2OS0xMTUuNzU5LTY5LjEzNmMtOS45ODYtMTguNTQyLTE1LjY1Ny0zOS43NC0xNS42NTctNjIuMjgNCgkJYzAtNzIuNTc5LDU4LjgzNy0xMzEuNDE1LDEzMS40MTUtMTMxLjQxNWMzMS44ODMsMCw2MS4xMjMsMTEuMzU2LDgzLjg3NiwzMC4yNTRsLTQyLjU2NiwzNC44NTQNCgkJYy0xMS45ODQtNy40ODUtMjYuMTQtMTEuODEzLTQxLjMxLTExLjgxM2MtMzQuMjUzLDAtNjMuMzY1LDIyLjA1NS03My45MDcsNTIuNzM4Yy0yLjcyOCw3Ljk1Ni00LjIxMywxNi40OTgtNC4yMTMsMjUuMzgzDQoJCWMwLDkuNjQyLDEuNzQzLDE4Ljg4NCw0Ljk1NiwyNy40MTJjMTEuMDk5LDI5LjYyNiwzOS42NjcsNTAuNzEsNzMuMTY0LDUwLjcxYzE0LjM5OSwwLDI3Ljg4My0zLjg4NiwzOS40NTQtMTAuNjg1DQoJCWMxNS4zNy05LjAxMywyNy4zODMtMjMuMTEyLDMzLjcxMS0zOS45OTZoLTcwLjI3OXYtNTEuOTk1aDcxLjI5Mmg1NC45MzlWMjMxLjQ0NnoiLz4NCjwvZz4NCjwvc3ZnPg0K" title='google+' width="28" />-->
    </a>
  </div>
</template>

<style>
 .icon{
    float: right;
    padding-top: 20px;
  }
  .icon img{
    margin-right: 10px;
  }
  @media (max-width: 500px){
    .icon{
      width: 100%;
    }
  }
</style>