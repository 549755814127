<template>
  <div class="center-block">
    <h2 class="page-title">
      Информация о нас
    </h2>
    <p class="help-desc" align="justify"><strong>Wayver</strong> – это on-line сообщество для людей, которые открывают для себя новые направления жизни, расширяют границы своих возможностей и практикуют активный и здоровый образ жизни. Миллионы людей каждый день ищут информацию о том или ином спортивном событии, эта информация разрозненна и зачастую не объективна, Wayver поможет получить системную и полезную информацию о любом спортивном событии будь то марафон, забег на 10 км, лыжные гонки или иной событийный туризм. Вы сможете зарегистрироваться на любое интересное для Вас событие, получить исчерпывающую информацию, взаимодействовать с другими людьми, которые имеют схожие интересы, получить советы по питанию и подготовки от опытных специалистов, подобрать для себя самый удобный маршрут и размещение.</p>
    <h2 class="page-title pt-4">
      Регистрация на события и старты
    </h2>
    <p class="help-desc" align="justify">Wayver содержит крупнейший каталог спортивных и развлекательных мероприятий, проходящих в любом уголке мира. Начиная с региональных забегов на короткие и длинные дистанции, велогонками, триатлоном и заканчивая серфкэмпами, трекингом и восхождениями. Поиск мероприятия — это легко, а on-line регистрация делает участие быстрым и удобным.Чтобы разместить Ваше мероприятие на wayver.ru, отправьте нам e-mail на <a href="mailto:teamwayver@gmail.com">teamwayver@gmail.com</a></p>
    <h2 class="page-title pt-4">
      Статьи, советы, рекомендации
    </h2>
    <p class="help-desc" align="justify">Мы размещаем проверенную оригинальную информацию, статьи и советы. Охватываем такие направления как бег, фитнес, трекинг, восхождения, питание, проживание, так же предлагаем различные лайфхаки для активных людей. Если вы хотите участвовать в создании качественного и полезного контента, обращайтесь в нашу редакционную команду по адресу <a href="mailto:teamwayver@gmail.com">teamwayver@gmail.com</a></p>
    <h2 class="page-title pt-4">
      Рассылка
    </h2>
    <p class="help-desc" align="justify">Мы осуществляем еженедельную и ежемесячную рассылку наших новостей, новых событий, подборку лучших советов и экипировки. Будь в курсе вместе с нами.</p>
    <h2 class="page-title pt-4">
      Работать с нами
    </h2>
    <p class="help-desc pb-4" align="justify">Мы всегда ищем новые идеи и людей. У вас есть идеи или возможность? Напишите нам <a href="mailto:teamwayver@gmail.com">teamwayver@gmail.com</a></p>
  </div>
</template>

<style lang="scss" scoped>
  .help-desc{
    padding: 0 55px;
  }
  .answer__item_content ul{
    list-style-type: none;
    padding-left: 0;
  }
  .answer__item_content ul li{
    border-bottom: 1px solid gray;
  }
</style>