<template>
  <div class="attr-icon">
    <img :src='getUrl(type, value)' :title="type" :alt="type" />
  </div>
</template>

<script>
  export default {
    name: 'icons',
    props: ['type', 'value'],
    data() {
      return {
        icons: {
          "Расстояние Бег": require("@/assets/img/icons/run.svg"),
          "Расстояние Плавание": require("@/assets/img/icons/swimming.svg"),
          "Расстояние Вело": require("@/assets/img/icons/bicycle.svg"),
          //"Сложность": require("@/assets/img/icons/complexity.svg"),
          "Набор высоты на велосипеде": require("@/assets/img/icons/bicycle1.svg"),
          "Сложность":{
            "1": require("@/assets/img/icons/complexity_1of5.svg"),
            "2": require("@/assets/img/icons/complexity_2of5.svg"),
            "3": require("@/assets/img/icons/complexity_3of5.svg"),
            "4": require("@/assets/img/icons/complexity_4of5.svg"),
            "5": require("@/assets/img/icons/complexity_5of5.svg")
          },
          "Рельеф": {
            "Равнины": require("@/assets/img/icons/plain.svg"),
            "Равнина": require("@/assets/img/icons/plain.svg"),
            "Горно-Равнинный": require("@/assets/img/icons/mountain_plain.svg"),
            "Горы": require("@/assets/img/icons/mountain.svg"),
            "Холмы": require("@/assets/img/icons/hills.svg"),
            "Горно-равнинный": require("@/assets/img/icons/mountain_plain.svg")
          },
          "Погодные условия": {
            "Холод": require("@/assets/img/icons/cold.svg"),
            "Жара": require("@/assets/img/icons/heat.svg"),
            "Ветер": require("@/assets/img/icons/wind.svg"),
            "Умеренные": require("@/assets/img/icons/middle_course.svg"),
          },
          "Температура воды": require("@/assets/img/icons/water_temperature.svg"),
          "Высота над уровнем моря": require("@/assets/img/icons/altitude.svg"),
          "Общее расстояние": require("@/assets/img/icons/total_distance.svg"),
          "Набор высоты": require("@/assets/img/icons/climb.svg"),
          "Ландшафт": {
            "Тропинки": require("@/assets/img/icons/paths.svg"),
            "Ледник": require("@/assets/img/icons/glacier.svg"),
            "Камни": require("@/assets/img/icons/the_stones.svg"),
            "Горы": require("@/assets/img/icons/mountain.svg"),
            "Пустыня": require("@/assets/img/icons/desert.svg"),
            "Холм": require("@/assets/img/icons/hill.svg"),
            "Лес": require("@/assets/img/icons/forest.svg")
          },
          "Уклон (минимум/максимум)": require("@/assets/img/icons/incline_min.svg"),
          "Перепад высоты": require("@/assets/img/icons/height_difference.svg"),
          "Количество дней": require("@/assets/img/icons/number_of_days.svg"),
          "Расстояние": require("@/assets/img/icons/distance.svg"),
          "Стиль ходьбы": {
            "Коньковый": require("@/assets/img/icons/skates.svg"),
            "Классический": require("@/assets/img/icons/classic.svg"),
            "Классический и коньковый": require("@/assets/img/icons/classic+skates.svg"),
          },
          "Течение": {
            "Слабое": require("@/assets/img/icons/weak_current.svg"),
            "Среднее": require("@/assets/img/icons/middle_course.svg"),
            "Сильное": require("@/assets/img/icons/strong_current.svg"),
          },
          "Количество препятствий": require("@/assets/img/icons/number_of_stages.svg"),
          "Место старта и финиша": require("@/assets/img/icons/start_finish.svg")
        }
      }
    },
    methods: {
      getUrl(icon, value) {
        if (this.icons[icon]) {
          if ( typeof this.icons[icon] === 'object' && this.icons[icon][value]) {
            return this.icons[icon][value]
          }
          if (this.icons[icon]) {
            return this.icons[icon];
          }
        }
        return icon;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .attr-icon{
    //width: 30px;
    margin: auto;
    padding-top: 5px;
    text-align: center;
    img{
      height:25px;
      max-width: 50px;
    }
  }
  @media (max-width: 599px){
    .attr-icon img{
      height:18px;
    }
  }
</style>
