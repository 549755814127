<template>
  <div class="center-block">
    <h2 class="page-title pt-4">
      Мой профиль
    </h2>
    <div class="row m-0 pt-2">
      <div class="col-md-4">
        <div class="user-avatar" data-bs-toggle="modal" data-bs-target="#avatarModal">
          <img :src="this.$store.state.currentUser.avatar" style='width: 100%;border-radius: 50%;' id='avatar' alt="">
          <strong class="text-center user-avatar-desc">{{this.$store.state.currentUser.firstName}} {{this.$store.state.currentUser.lastName}}</strong>
        </div>
      </div>
      <div class="col-md-8 m-0 prfile-data" style="max-width:700px;margin-left:10px; padding-right:40px">
        <strong>Настройки аккаунта</strong>

        <form @submit="saveProfile">
          <div class="row pt-4">
            <div class="col-4">
              <span class="title-profile-field">Имя</span>
            </div>
            <div class="col-8">
              <input type="text" class="form-control" placeholder="Введите имя" required='true' v-model="firstName"  />
            </div>
          </div>

          <div class="row pt-2">
            <div class="col-4">
              <span class="title-profile-field">Фамилия</span>
            </div>
            <div class="col-8">
              <input type="text" class="form-control" placeholder="Введите фамилию" v-model="lastName" />
            </div>
          </div>

          <div class="row pt-2">
            <div class="col-4">
              <span class="title-profile-field">Почта</span>
            </div>
            <div class="col-8">
              <input type="email" class="form-control" placeholder="Введите email" required='true' v-model="username" />
            </div>
          </div>

          <button type='submit' class="btn btn-success mt-4 color-btn-green">Сохранить настройки</button>
        </form>

        <form @submit="changePass">
          <div class="row pt-4">
            <div class="col">
              <strong>Пароль</strong>
            </div>
          </div>

          <div class="row pt-4">
            <div class="col-4">
              <span class="title-profile-field">Новый пароль</span>
            </div>
            <div class="col-8">
              <input type="text" v-model="newPass" class="form-control" required='true' placeholder="Введите новый пароль" />
            </div>
          </div>

          <div class="row pt-2">
            <div class="col-4">
              <span class="title-profile-field">Потвердить пароль</span>
            </div>
            <div class="col-8">
              <input type="text" v-model="confirmPass" class="form-control" required='true' placeholder="Повторно введите пароль" />
            </div>
          </div>

          <button type='submit' class="btn btn-success mt-4 color-btn-green" >Изменить пароль</button>
        </form>

      </div>
    </div>
  </div>

  <!-- MODAL -->
  <div class="modal fade" id="avatarModal" tabindex="-1" aria-labelledby="avatarModal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="avatarModalLabel" >Загрузка изображения</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="drop-box">
          <input type="file" class="input-file" ref='inputfile' @change="onChange"/>
          <p class="pt-2">
            Перетащите сюда свой файл или нажмите для выбора
          </p>
        </div>
        <div class="upload-img-container">
           <cropper
              ref='cropper'
              v-if="imgDataUrl.length > 0"
              class="cropper"
              :src="imgDataUrl"
              :stencil-props="{
                aspectRatio: 1
              }"
            ></cropper>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отмена</button>
        <button type="button" class="btn btn-primary" @click="cropImage" data-bs-dismiss="modal">Сохранить</button>
      </div>
    </div>
  </div>
</div>

</template>

<style>
  .cropped-image{
    width: 360px;
    margin: auto;
    display: block;
  }
  .title-profile-field{
    color: #919090;
  }
  input[type="email"],input[type="password"] {
    background: transparent;
    border: none;
    border-bottom: 1px solid #000000;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
  }
  input[type="email"]:focus,input[type="password"]:focus  {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .prfile-data{
    max-width:700px;
    margin-left:10px; 
    padding-right:40px
  }
  @media (max-width: 767px) {
    .user-avatar {
      margin: auto;
      margin-bottom: 20px;
      margin-left: auto !important;
    }
    .prfile-data{
      padding: 0 55px !important;
    }
  }
</style>

<script>
import { mapState } from "vuex";
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

export default ({
  data(){
    return{
      newPass: '',
      confirmPass: '',
      genderOptions: [
        'Мужской',
        'Женский'
      ],
      imgDataUrl: '',
    }
  },
  components: { Cropper },
  computed: {
    ...mapState(["currentUser"]),
    firstName: {
      get() {
        return this.currentUser.firstName;
      },
      set(firstName) {
        return this.$store.commit('SET_USER_DATA', {firstName});
      }
    },
    lastName: {
      get() {
        return this.currentUser.lastName;
      },
      set(lastName) {
        return this.$store.commit('SET_USER_DATA', {lastName});
      }
    },
    username: {
      get() {
        return this.currentUser.username;
      },
      set(username) {
        return this.$store.commit('SET_USER_DATA', {username});
      }
    }
  },
  mounted() {
    this.check_auth();
  },
  methods:{
    saveProfile(e){
      e.preventDefault();
      this.$store.dispatch('UPDATE_USER').then((response) => {
        alert("Данные успешно сохранены.");
      }, reason => {
        alert("При изменение данных произошла ошибка.");
      });
    },
    changePass(e){
      e.preventDefault();
      if(this.newPass != this.confirmPass){
        alert('Пароли не совпадают.')
        return;
      }
      this.$store.dispatch('SET_NEW_PASSWORD', this.newPass).then((response) => {
        alert("Пароль успешно изменен.");
      }, reason => {
        alert("При изменение пароля произошла ошибка.");
      });
    },
    onChange: function (e) {
      const file = e.currentTarget.files[0];
      this.$store.dispatch('UPLOAD_FILE', file).then((response) => {
        this.imgDataUrl = '';
        this.$store.dispatch('DOWNLOAD_FILE', response).then((response) => {
          this.imgDataUrl = URL.createObjectURL(response);
        });
      });
    },
    cropImage(){
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        canvas.toBlob(blob => {
          this.$store.dispatch('UPLOAD_FILE', blob).then((response) => {
            this.$store.commit('UPDATE_USER_AVATAR', response);
            this.$store.dispatch('UPDATE_USER');
          });
        })
      }
    }
  }
})
</script>
