<template>
  <footer class="footer mt-auto">
    <div class="footer__content">
      <div class="footer__menu">
        <a href="#" class="footer__menu_item">WAYVER</a>
        <nav class="footer__submenu">
          <a href="#" class="footer__submenu_item">О нас</a>
          <a href="#" class="footer__submenu_item">Техническая поддержка</a>
          <a href="#" class="footer__submenu_item">Контакты</a>
        </nav>
      </div>
      <div class="footer__menu">
        <a href="#" class="footer__menu_item">События</a>
        <nav class="footer__submenu">
          <a href="#" class="footer__submenu_item">Рекомендованные события</a>
          <a href="#" class="footer__submenu_item">График выбранных событий</a>
        </nav>
      </div>
    </div>
  </footer>
</template>