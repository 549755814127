<template>
  <!--<div class="event-header" :style="{'background-image': 'url(' + require('@/assets/img/event.jpg') + ')'}">-->
  <div class="event-header" v-if="data.images" :style="{'background-image': 'url(https://wayver.ru/v1/attachment/download/'+ data.images[0].attachment +')'}">
    <nav-header></nav-header>
    <div class="container header-size">
      <p class="event-title">{{ data.title }}</p>
      <p class="event-tdesc" style="max-width:600px">{{ data.intro }}</p>
      <p class="event-tdesc event-date pt-4" style="font-size:30px; font-weight:bold;">{{ eventDate(data.startDateLocal, data.endDateLocal )}}</p>
      <p class="event-tdesc event-date">{{ data.country }} - {{ data.town }}</p>
      <div class="playsInfo">

        <div class="attr-block">
          <div class="attr" v-for="(value, name) in data.playsInfo[0].info" v-bind:key="name">
              <AttrIcon :type="name" :value="value"></AttrIcon>
              <p class="text-center pt-1" v-if="value">
                {{String(value).substr(0,12)}}
              </p>            
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="center-block" v-if="data.title">
    <div class="row m-0">
      <div class="col-xl-2">
        <div class="user-avatar pt-4">
          <a :href="'/ambasador/' + data.createdUser.id">
            <img :src="user_avatar()" alt="" style='border-radius:50px;display: block;margin: auto;'>
            <strong class="text-center user-avatar-desc">{{ data.createdUser.firstName }} {{ data.createdUser.lastName }}</strong>
          </a>
        </div>
      </div>
      <div class="col-xl-8 m-0 p-0">

        <div class="event-desc-block pt-2" style='position: relative'>
          <h4 class="page-title pt-4">
            Общая информация
          </h4>
          <p class="event-desc"  v-html="data.descriptionHtml"></p>
          <div class="event-images">

            <swiper :breakpoints="breakpoints" :navigation="{ nextEl: '.nextArrow', prevEl: '.prevArrow' }">
              <swiper-slide v-for="image in images" v-bind:key="image.id">
                <img class="event-image" style='height: 100%; width: 100%; object-fit: contain' @click="full_image(image)" :src="first_image_url(image)" alt="">
              </swiper-slide>
            </swiper>

          </div>

          <div class="swipper-btn">
              <button class="events__control_prev events__control_arrow prevArrow" >
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                  y="0px" viewBox="0 0 447.243 447.243" style="enable-background:new 0 0 447.243 447.243;"
                  xml:space="preserve">
                  <path d="M420.361,192.229c-1.83-0.297-3.682-0.434-5.535-0.41H99.305l6.88-3.2c6.725-3.183,12.843-7.515,18.08-12.8l88.48-88.48
                    c11.653-11.124,13.611-29.019,4.64-42.4c-10.441-14.259-30.464-17.355-44.724-6.914c-1.152,0.844-2.247,1.764-3.276,2.754
                    l-160,160C-3.119,213.269-3.13,233.53,9.36,246.034c0.008,0.008,0.017,0.017,0.025,0.025l160,160
                    c12.514,12.479,32.775,12.451,45.255-0.063c0.982-0.985,1.899-2.033,2.745-3.137c8.971-13.381,7.013-31.276-4.64-42.4
                    l-88.32-88.64c-4.695-4.7-10.093-8.641-16-11.68l-9.6-4.32h314.24c16.347,0.607,30.689-10.812,33.76-26.88
                    C449.654,211.494,437.806,195.059,420.361,192.229z" />
                </svg>
              </button>

              <button class="events__control_next events__control_arrow nextArrow">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                  y="0px" viewBox="0 0 492.004 492.004" style="enable-background:new 0 0 492.004 492.004;"
                  xml:space="preserve">
                  <path d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136
                    c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002
                    v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864
                    c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872
                    l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z" />
                </svg>
              </button>
            </div>

        </div>

        <div class="event-desc-block">
          <h4 class="page-title pt-4">
            Полезные советы
          </h4>
          <div v-if="data.usefulInformation">
            <ul class="usefulInformation">
              <li v-for="txt in JSON.parse(data.usefulInformation)" v-bind:key="txt">{{txt}}</li>
            </ul>
          </div>
        </div>

        <div class="event-desc-block" v-if="data.packageCompositionHtml && data.packageCompositionHtml.length > 15">
          <h4 class="page-title pt-4">Состав пакета</h4>
          <p class="event-desc pb-4" v-html="data.packageCompositionHtml"></p>
          <img :src="first_image_url(data.packageCompositionImages)" style='height: 100%; width: 100%; object-fit: contain' v-if="data.packageCompositionImages" />
        </div>

        <div class="event-desc-block" v-if="data.tourDescriptionHtml && data.tourDescriptionHtml.length > 15">
          <h4 class="page-title pt-4">Описание тура</h4>
          <p class="event-desc pb-4" v-html="data.tourDescriptionHtml"></p>
          <img :src="first_image_url(data.tourDescriptionImages)" style='height: 100%; width: 100%; object-fit: contain' v-if="data.tourDescriptionImages" />
        </div>

        <div class="event-desc-block" v-if="data.necessaryEquipmentHtml && data.necessaryEquipmentHtml.length > 15">
          <h4 class="page-title pt-4">Необходимое снаряжение</h4>
          <p  class="event-desc pb-4" v-html="data.necessaryEquipmentHtml"></p>
          <img :src="first_image_url(data.necessaryEquipmentImages)" style='height: 100%; width: 100%; object-fit: contain' v-if="data.necessaryEquipmentImages" />
        </div>

        <div class="event-desc-block pb-4 mt-4" v-if="data.necessaryEquipmentVideoId">
          <div class="event-desc-block video-container">
            <iframe width="100%" frameborder="0" height="100%" allowfullscreen :src="'https://www.youtube.com/embed/' + data.necessaryEquipmentVideoId"></iframe> 
          </div>
        </div>
      </div>

      <div class="col-xl-2">
        <div class="event-info">
          <button type="button" class="btn btn-primary" @click='goToLink()' style='margin: auto;display: block; background-color:#5362f6'>Купить</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row m-0">
    <div class="col-xl-2" style="background: #5362f6;"></div>
    <div class="col-xl-8 m-0 p-0">
      <div class="important-info pb-4">
        <h4 class="page-title pt-4">
          Wayver совет
        </h4>
        <p  style='padding-right: 40px' class="pb-4 pb-4">{{data.importantInformation}}</p>
      </div>
    </div>
  </div>

  <div class="center-block" v-if="data.title">
    <div class="row m-0">
      <div class="col-xl-2"></div>
      <div class="col-xl-8 m-0 p-0">

        <div class="event-desc-block">
          <h4 class="page-title pt-4">
            Как добраться и чем заняться
          </h4>
          <div v-for="av in data.advice" v-bind:key="av"> 
            <div class="pt-2" v-if="av.descriptionHtml.length > 15 || av.title.length > 0">
              <strong v-if="av.latitude != nil">{{av.title}}</strong>
              <strong style='color:#5362f6; margin-top:20px;display: block' v-if="av.latitude == nil">{{av.title}}</strong>
              <p class="event-desc" v-html="av.descriptionHtml" v-if='av.descriptionHtml.length > 15'></p>
            </div>
          </div>
        </div>

        <div class="event-desc-block pt-4" v-if="data.latitude">
          <Map :center='center' :advice='data.advice'></Map>
        </div>

        <!--
        <div class="event-desc-block">
          <div>
            <h4 class="page-title pt-4">
              Дополнительная информация
            </h4>
            <p  class="event-desc" v-html="data.additionalInformationHtml"></p>
          </div>
        </div>
        -->

        <div class="event-desc-block pb-4" v-if="data.videoId">
          <div class="video-container mt-4" style="min-height: 400px">
            <iframe width="100%" frameborder="0" height="100%" style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px" allowfullscreen :src="'https://www.youtube.com/embed/' + data.videoId"></iframe> 
          </div>
        </div>
      </div>
      <div class="col-xl-2">
        <div class="event-info">
          <button type="button" class="btn btn-primary" @click='goToLink()' style='margin: auto;display: block; background-color:#5362f6'>Купить</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row m-0 linkedEvent pb-4" v-if="linkedEvent.length > 0">
    <div class="col-md-2"></div>
    <div class="col-md-10">
      <h4 class="page-title pt-4">
        Вам может быть интересно
      </h4>

      <swiper :slides-per-view="3" :space-between="10">
        <swiper-slide v-for="topevent in linkedEvent" v-bind:key="topevent.id">
          <a :href="'/event/' + topevent.id" class="events__inner_slide swiper-slide">
            <img class="event-image" :src="image_url(topevent.images)" alt="">
            <div class="events__inner_slide-desc">
              <div class="events__inner_slide-head">
                {{topevent.title.substr(0,30)}}
                <span>{{ topevent.country }}</span>
              </div>
              <div class="events__inner_slide-date">
                {{ startEndDate(topevent.startDateLocal,topevent.endDateLocal) }}<br>
                {{topevent.endDateLocal.substr(0,4)}}
              </div>
            </div>
          </a>
        </swiper-slide>
      </swiper>

    </div>
  </div>

<div class="modal fade" id="imgModal" tabindex="-1" aria-labelledby="imgModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <img style='height: 100%; width: 100%; object-fit: contain' :src="first_image_url(modalImg)" alt="">
      </div>
    </div>
  </div>
</div>

</template>

<style lang="scss" scoped>
  .page-title{
    padding-left: 0px;
    font-size: 32px;
  }
  .playsInfo{
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
    width: max-content;
    //padding-left: 10px;
  }
  .center-block{
    padding-top: 0px !important;
  }
  .event-info{
    /*background-color: #f5f5f5;*/
    width: 100%;
    padding-top:20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    position: sticky;
    top: 0;
  }
  .event-title{
    color: white;
    font-size: 38px;
    padding-top: 100px !important;
    font-weight: bold;
  }
  .event-tdesc{
    color: white;
  }
  .event-header{
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    height: 606px;
    .navbar {
      background: transparent !important;
      top: 75px;
    }
  } 
  .event-logo{
    position: absolute;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
  }
  .event-images{
    padding-top: 20px;
    max-width: 700px;
  }
  .event-image{
    display: block;
    margin: auto;
    height: 650px;
  }
  .map{
    width: 100%; 
    height: 500px
  }
  .iframe-video{
    overflow:hidden;
    overflow-x:hidden;
    overflow-y:hidden;
    height:100%;
    width:100%;
    position:absolute;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px
  }
  .attr-icon{
    border-radius: 5px;
    border: 1px solid gray;
    background: white;
    width: 50px;
    height: 50px;
    vertical-align: middle;
    display: table-cell;
    padding-top: 0px !important;
  }
  .attr{
    display: inline-block;
    //border-radius: 5px;
    //border: 1px solid gray;
    //padding-right: 5px;
    //padding-left: 5px;
    font-size: 12px;
    width: 50px;
    margin-right: 8px;
    //width: 70px;
    //padding-top: 10px;
    //margin-right: 12px;
    padding-bottom: 10px;
    //background: white;
    color: white;
    vertical-align: top;
    p{
      line-height: 1.2;
    }
  }
  .important-info{
    padding-left: 80px;
    background: #5362f6;
    color: white;
    .page-title {
      color: white;
    }
  }
  .linkedEvent .swiper-slide{
    max-width: 255px;
    height: 360px;
  }
  .swiper-slide{
    margin-right: 20px !important;
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .video-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  }
  .swipper-btn{
    right: 0;
    position: absolute;
    z-index: 12;
    margin-top: -60px;
    margin-right: 40px;
  }
  .usefulInformation{
    padding: 0;
    //list-style: none;
    margin-left: 0.5rem;
  }
  .usefulInformation li{
    margin-bottom: 10px;
  }
  .usefulInformation li::before {
    //content: "\2022";
    //color: #5362f6;
    //display: inline-block;
    //width: 0.5em;
    //margin-left: -0.5em;
  }

  .header-size {
    padding: 0 55px;
  }

  @media (max-width: 1280px){
    .events__control_arrow{
      padding: 15px;
      position: initial;
    }
    .event-images{
      max-width: 100%;
    }
  }
  @media (max-width: 1200px){
    .user-avatar{
      display: none;
    }
    .event-desc-block{
      padding: 0 55px;
    }
    .important-info{
      padding-left: 25px;
      padding-right: 10px;
      p{
        padding-right: 10px !important;
      }
    }
    .video-container{
      height: 100%;
    }
    .video-container iframe{
      position: relative;
    }
    .event-info{
      padding-bottom: 20px;
      text-align: center;  
    }
    .event-info button{
      margin: auto;
      text-align: center;  
    }
  }

  @media (max-width: 900px){
    .playsInfo{
      width: 100%;
    }
  }

  @media (max-width: 990px){
    .event-title{
      font-size: 30px;
    }
  }

  @media (max-width: 770px){
    .event-title{
      font-size: 25px;
    }
    .event-tdesc{
      font-size: 14px;
    }
  }

  @media (max-width: 599px){
    .swiper-slide{
      margin-right: 0px !important;
    }
    .event-desc-block{
      padding: 0 25px;
    }
    .header-size{
      padding: 0 15px;
    }
    .usefulInformation li{
      font-size: 12px !important;
    }
    .playsInfo{
      .attr{
        padding-left: 0px;
        //padding-right: 15px;
        width: 40px;
      }

      .attr-icon{
        width: 40px;
        height: 40px;
        padding-top: 0;
      }
      p{
        font-size: 8px;
      }
      //display: none;
    }
    .events__control_arrow{
      padding: 7px;
      position: initial;
    }
    .swipper-btn{
      margin-top: -40px;
    }
    .event-date{
      font-size: 20px !important;
    }
    .event-title{
      font-size: 25px !important;
    }
    .video-container {
      padding-left: 0;
      padding-right: 0;
    }
    .event-header .navbar{
      top:0;
    }
    .event-title{
      padding-top: 0px !important;
    }
    .playsInfo .attr{
      margin-bottom: 5px;
    }
  }
  
</style>

<script>
import AttrIcon from '@/components/icons'
import NavHeader from '@/pages/layout/nav'
import Map from '@/components/map'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Modal } from 'bootstrap';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default {
  data(){
    return {
      data: {},
      images: [],
      center: { lat: 40.689247, lng: -74.044502 },
      link: '',
      linkedEvent: [],
      modalImg: {},
      breakpoints:{
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }
    }
  },
  components:{ Swiper, SwiperSlide, NavHeader, AttrIcon, Map  },
  mounted() {
    this.$store.dispatch('GET_EVENT_BY_ID', this.$route.params.id).then((response) => {
      this.data = response;
      this.images = this.data.images
      this.center = { lat: response.latitude, lng: response.longitude, title: this.data.title, desc: this.data.intro }
      if(this.data.linkedEventIds && this.data.linkedEventIds.length > 0){
        this.$store.dispatch('GET_RELATED_EVENTSD', this.data.linkedEventIds).then((res) => {
          this.linkedEvent = res;
        });
      }
    });
    this.$store.dispatch('GET_EVENT_LOTS_BY_ID', this.$route.params.id).then((response) => {
      if(response && response.length > 0){
        let desc = JSON.parse(response[0].description);
        if(desc && desc.blocks.length > 0){
          this.link = desc.blocks[0].text;
        }
      }
    });
  },
  methods:{
    eventDate(start, end){
      let date_start = start.split('-');
      let date_end = end.split('-');

      date_start = date_start[2] + "." + date_start[1] + "." + date_start[0];
      date_end = date_end[2] + "." + date_end[1] + "." + date_end[0];
      if(date_start == date_end){
        return date_start;
      }
      return date_start + " - " + date_end;
    },
    startEndDate(date1,date2){
      let date_start_arr = date1.split('-');
      let date_end_arr = date2.split('-');
      return date_start_arr[2] + " — " + date_end_arr[2] + " " + this.months[parseInt(date_end_arr[1]) - 1];
    },
    user_avatar(){
      if(this.data.createdUser && this.data.createdUser.image){
        return this.$store.getters.MAIN_URL + this.data.createdUser.image.assets['80x80'];
      }
      return require("@/assets/img/img-ava2.jpg");
    },
    goToLink(){
      if(this.link.length > 0){
        window.open(this.link);
      }
    },
    full_image(image){
      //let src = this.first_image_url(image);
      this.modalImg = image;
      var myModal = new Modal(document.getElementById('imgModal'), { keyboard: false });
      myModal.show();
    }
  }
}
</script>