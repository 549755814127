<template>
  <div class="top-events__item" @click="goToEvent()">
    <div class="top-events__item_head">
      <img :src="image_url(event_data.images, '640x480')" alt="">
      <div class="top-events__item_head-top">
        <span>{{ event_data.town }}, {{ event_data.country }}</span>
        <button>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"
            xml:space="preserve">
            <path d="M416.667,0H95.334c-8.284,0-15,6.716-15,15v482c0,6.067,3.655,11.536,9.26,13.858c1.856,0.769,3.805,1.142,5.737,1.142
            c3.903,0,7.74-1.523,10.609-4.394l150.063-150.062L406.06,507.606c4.29,4.291,10.741,5.573,16.347,3.252
            c5.605-2.322,9.26-7.791,9.26-13.858V15C431.667,6.716,424.952,0,416.667,0z" />
          </svg>
        </button>
      </div>
      <div class="top-events__item_head-bottom">
        <div>
          <strong class="title">{{event_data.title}}</strong>
          <span>{{ startEndDate(event_data.startDateLocal,event_data.endDateLocal) }}</span>
        </div>
        <img src="@/assets/img/stars.png" alt="">
      </div>
    </div>

    <div class="top-events__item_content">
      <div class="top-events__header">
        <img :src="userAvatar(event_data.createdUser)" style='width:27px;border-radius: 20px;' alt="">
        <!--
        <img src="@/assets/img/user.png" alt="">
        -->
        <span>{{ event_data.startDateLocal }}</span>
        <a href="#">by {{ event_data.createdUser.firstName }} {{ event_data.createdUser.lastName }}</a>
      </div>

      <div class="top-events__text" v-html="eventDesc(event_data.descriptionHtml)">
      </div>
    </div>
  </div>
</template>

<script>
export default({
  name: "event-card",
  props: {
    event_data: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  methods:{
    eventDesc(text){
      text = text.replace("<p>", '').replace("</p>", '');
      if(text.length > 145){
        text = text.substr(0, 145) + "...";
      }
      return text;
    },
    startEndDate(date1, date2){
      let date_start_arr = date1.split('-');
      let date_end_arr = date2.split('-');
      return date_start_arr[2] + " — " + date_end_arr[2] + " " + this.months[parseInt(date_end_arr[1]) - 1];
    },
    goToEvent(){
      this.$router.push({name: 'event', params: { id: this.event_data.id }});
    },
    userAvatar(user){
      if(user && user.image && user.image.attachment){
        return this.$store.getters.MAIN_URL + user.image.assets['80x80'];
      }
      return require("@/assets/img/user.png");
    }
  }
})
</script>

<style scoped>
  .top-events__item:hover{
    cursor: pointer;
  }
</style>
