<template>
  <nav class="container navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a href="/" class="navbar-brand">
        <img src="@/assets/img/logo-new.png" alt="">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="/">Главная</a>
          </li>
          <li class="nav-item">
            <router-link to="/about" v-bind:class="{ active: ($route.fullPath == '/about') }" class='nav-link'>О проекте</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/help" v-bind:class="{ active: ($route.fullPath == '/help') }" class='nav-link'>Помощь</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/login" v-bind:class="{ active: ($route.fullPath == '/login') }" class='nav-link' v-if="!$store.state.isAuth">Войти</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/reg" v-bind:class="{ active: ($route.fullPath == '/reg') }" class='nav-link' v-if="!$store.state.isAuth">Регистрация</router-link>
          </li>
          <li class="nav-item profile-link-mobile">
            <a class="nav-link" aria-current="page" href="/user/profile" v-if="$store.state.isAuth">Профиль</a>
          </li>
          <li class="nav-item profile-link-mobile" @click="exit()">
            <a class="nav-link" aria-current="page" href="#" v-if="$store.state.isAuth">Выход</a>
          </li>
          <li class="profile-link" v-if="$store.state.isAuth" style="padding-left:60px">
            <div class="dropdown">
              <button type="button" id="dropdownMenuUser" data-bs-toggle="dropdown" aria-expanded="false">
                <div class="header__user">
                  <div class="header__user_pic">
                    <img :src="$store.getters.USER_AVATAR" style='width: 33px;border-radius: 20px;' alt="">
                  </div>
                  <img src="@/assets/img/arrow.png" alt="" class="arrow">
                </div>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuUser">
                <li>
                  <small>
                    <a class="dropdown-item" href="/user/profile">Профиль</a>
                  </small>
                </li>
                <li @click="exit()">
                  <small>
                    <a class="dropdown-item" href="#">Выход</a>
                  </small>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style scoped>
  .navbar{
    padding-top: 10px;
    background-color: #5362f6 !important;
  }
  .nav-link{
    font-size: 12px;
    font-weight: 600;
    color:white !important;
  }
  .nav-link:hover{
    color: #0a58ca !important;
  }
  .mini-header{
    height: 120px;
  }
  .navbar-brand img{
    width: 160px;
  }
  .nav-link.active {
    color: #0d0e0f !important;
  }
  .profile-link-mobile{
    display: none;
  }
  @media (max-width: 991px){
    .profile-link{
      display: none;
    }
    .profile-link-mobile{
      display: inline;
    }
  }
</style>

<script>
export default {
  methods:{
    exit(){
      this.$store.dispatch('LOGOUT');
      this.$router.push('/');
    }
  }
}
</script>