<template>
  <section class="hero" :style="{'background-image': 'url(' + require('@/assets/img/main-img.jpg') + ')'}">
    <header class="header">
      <a href="/" class="header__logo">
        <img src="@/assets/img/logo-new.png" alt="">
      </a>
      <div class="header__right">
        <nav class="header__menu">
          <a href="#" class="header__menu_item header__menu_active">Главная</a>
          <router-link to="/about" class='header__menu_item'>О проекте</router-link>
          <router-link to="/help" class='header__menu_item'>Помощь</router-link>
          <router-link to="/login" class='header__menu_item' v-if="!$store.state.isAuth">Войти</router-link>
          <router-link to="/reg" class='header__menu_item' v-if="!$store.state.isAuth">Регистрация</router-link>
        </nav>

        <div class="header__profile" v-if="$store.state.isAuth">
          <div class="dropdown">
            <button type="button" id="dropdownMenuUser" data-bs-toggle="dropdown" aria-expanded="false">
              <div class="header__user">
                <div class="header__user_pic">
                  <img :src="$store.getters.USER_AVATAR" style='width: 33px;border-radius: 20px;' alt="">
                </div>
                <img src="@/assets/img/arrow.png" alt="" class="arrow">
              </div>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuUser">
              <li>
                <small>
                  <a class="dropdown-item" href="/user/profile">Профиль</a>
                </small>
              </li>
              <li @click="exit()">
                <small>
                  <a class="dropdown-item" href="#">Выход</a>
                </small>
              </li>
            </ul>
          </div>

        </div>
      </div>

      <button type="button" class="header__menu-btn js-menu-open" @click="menuOpen()">
        <span>Меню</span>
        <svg viewBox="0 -53 384 384" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
          <path
            d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
          <path
            d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
        </svg>
      </button>

    </header>

    <div class="hero__content">
      <h1 class="hero__head">Найди свое событие на WAYVER</h1>
      <div class="hero__search" style='position: relative;'>
        <!--<div class="hero__search_labels">
          <label for="search-query">Поиск событий, статей и новостей</label>
          <label for="search-city">Страна,город</label>
        </div>
        -->
        <div class="hero__search_inputs">
          <input type="text" id="search-query" @input="getItems()" v-model="search.query" placeholder="Поиск событий, статей и новостей">
          <div class="search-result-mobile" style='display:none;' v-if="showResult">
            <ul>
              <li v-for="item in items" v-bind:key="item.id">
                <a :href="'/' + item.type + '/' + item.id" class="events__inner_slide swiper-slide" v-if='item.id != null'>{{item.name}}</a>
                <a class="events__inner_slide swiper-slide" @click="setKeyWord(item.name)" v-if='item.id == null'>{{item.name}}</a>
              </li>
            </ul>
          </div>
          <input type="text" id="search-city" placeholder="Страна, город" ref="origin">   
          <button class="app-search" type="submit" @click="find()">Искать</button>
        </div>
        <div class="search-result" v-show="showResult" style='position: absolute;'>
          <ul>
            <li v-for="item in items" v-bind:key="item.id">
              <a :href="'/' + item.type + '/' + item.id" class="events__inner_slide swiper-slide" v-if='item.id != null'>{{item.name}}</a>
              <a class="events__inner_slide swiper-slide" @click="setKeyWord(item.name)" v-if='item.id == null'>{{item.name}}</a>
            </li>
          </ul>
        </div>
        <div class="block-search-date" style='width:40%;position: absolute;'>
          <a class="text-date-search" @click="showPeriod=!showPeriod">Искать по периоду</a>
          <div v-show="showPeriod || (search.dateRangeStart && search.dateRangeStart.length > 0) || (search.dateRangeEnd && search.dateRangeEnd.length > 0)">
            <!--
            <div class="input-group mb-3">
              <input type='date' class="form-control border-radius0" inputFormat='yyyy-MM-dd' placeholder="с" v-model="search.dateRangeStart" />
              <span class="input-group-text">-</span>
              <input type='date' class="form-control border-radius0" inputFormat='yyyy-MM-dd' placeholder="по" v-model="search.dateRangeEnd" />
            </div>
            -->
            <div class="row">
              <div class="col pe-0" style='border-right: 1px solid gray;'>
                <datepicker inputFormat='yyyy-MM-dd' style='border-bottom: none;' v-model="search.dateRangeStart" class="form-control border-radius0" placeholder="с"></datepicker>
              </div>
              <div class="col ps-0 datepicker_to">
                <datepicker inputFormat='yyyy-MM-dd' style='border-bottom: none; right:0' v-model="search.dateRangeEnd" class="form-control border-radius0" placeholder="по"></datepicker>
              </div>
            </div>
          </div>
        </div>
        <button class="mobile-search" style='display:none' type="submit" @click="find()">Искать</button>
      </div>
    </div>
  </section>

  <div class="overlay"></div>
  <div class="modal-menu">
    <div class="modal-menu__header">
      <button class="modal-menu__close js-menu-close" @click="menuClose()">
        <span>Закрыть</span>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 47.971 47.971" style="enable-background:new 0 0 47.971 47.971;" xml:space="preserve">
          <path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88
          c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242
          C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879
          s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z" />
        </svg>
      </button>
    </div>

    <div class="modal-menu__profile">
      <nav class="modal-menu__nav">
        <a href="/" class="modal-menu__nav-item">Главная</a>
        <a href="/about" class="modal-menu__nav-item">О проекте</a>
        <a href="/help" class="modal-menu__nav-item">Помощь</a>
        <a href="/login" class="modal-menu__nav-item" v-if="!$store.state.isAuth">Войти</a>
        <a href="/reg" class="modal-menu__nav-item" v-if="!$store.state.isAuth">Регистрация</a>
        <a href="/user/profile" class="modal-menu__nav-item" v-if="$store.state.isAuth">Профиль</a>
        <a href="#" class="modal-menu__nav-item" v-if="$store.state.isAuth" @click="exit()">Выход</a>
      </nav>
    </div>
  </div>
</template>

<style scoped>
  .search-result-mobile,
  .search-result{
    background: white;
    color: gray;
    width: 40%;
    border-left: 2px solid gray;
    position: sticky;
    z-index: 999;
  }
  .app-search{
    background-color: #5362f6;
    border-radius: 7px 7px 7px 7px;
  }
  @media (max-width: 599px) {
    .search-result{
      width: 100%;
      display:none !important;
    }
    .search-result-mobile{
      display: block !important;
      width: 100%;
    }
    .block-search-date{
      width: 100% !important;
      position: relative !important;
    }
    .app-search{
      display:none;
    }
    .mobile-search{
      display: inline !important;
    }
  }

  .search-result-mobile  ul,
  .search-result ul{
    padding: 0;
    font-size: 12px;
    list-style-type: none;
  }
  .search-result-mobile  ul li,
  .search-result ul li{
    border-bottom: 1px solid gray;
    padding: 7px;
    height: 32px;
  }
  .search-result-mobile  ul li:hover,
  .search-result ul li:hover{
    cursor: pointer;
    background: rgb(189, 188, 188);
    color: white;
  }
  .search-result-mobile{
    display: none;
    position: absolute;
    margin-top: 51px;
    border-left: none;
  }
  .text-date-search{
    font-size: 13px;
    color: #e2e6e9;
  }
  .text-date-search:hover{
    cursor: pointer;
  }
  .mobile-search{
    background-color: #5362f6;
    color: #fff;
    padding: 0 55px;
    font-size: 15px;
    font-weight: 600;
    height: 50px;
    width: 100%;
  }
</style>

<script>
import Datepicker from 'vue3-datepicker'
import moment from 'moment'

export default {
  data() {
    return{
      search: { query: '', address: '', lat: '', lng: '', dateRangeStart: null, dateRangeEnd: null },
      items: [],
      showResult: false,
      showPeriod: false,
      dateRangeStart: null
    }
  },
  created: function() {
    document.addEventListener('click', this.bindClick);
  },
  components: {
    Datepicker, moment
  },
  mounted() {
    const autocomplete = new google.maps.places.Autocomplete(this.$refs["origin"]);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      this.search.address = place.formatted_address;
      this.search.lat = place.geometry.location.lat();
      this.search.lng = place.geometry.location.lng();
    });

    if(this.$route.query.query){
      this.search.query = this.$route.query.query;
    }
    if(this.$route.query.address){
      this.search.address = this.$route.query.address;
    }
    if(this.$route.query.lat){
      this.search.lat = this.$route.query.lat;
    }
    if(this.$route.query.lng){
      this.search.lng = this.$route.query.lng;
    }
    console.log(this.$route.query.dateRangeStart);
    if(this.$route.query.dateRangeStart){
      this.search.dateRangeStart = new Date(this.$route.query.dateRangeStart);
      this.showPeriod = true;
      //this.search.dateRangeStart = this.$route.query.dateRangeStart;
    }
    console.log(this.$route.query.dateRangeEnd);
    if(this.$route.query.dateRangeEnd){
      this.search.dateRangeEnd = new Date(this.$route.query.dateRangeEnd);
      this.showPeriod = true;
      //this.search.dateRangeEnd = this.$route.query.dateRangeEnd;
    }
    this.find();
  },
  methods:{
    getItems(){
      this.items = [];
      if(this.search.query.length < 2) { return; }
      this.$store.dispatch('SEARCH_AUTOCOMPLETE', this.search.query).then((response) => {
        if(response && response.length > 0){
          this.showResult = true;
          for(var i = 0; i < response.length; i++){
            this.items.push({ name: response[i].title, id: response[i].id, type: response[i].objectType });
          }
        }
      });
      if(this.items.length == 0){ this.showResult = false; }
    },
    find(){
      let date_from = null;
      if(this.search.dateRangeStart){
        date_from = moment(this.search.dateRangeStart).format('YYYY-MM-DD');
      }
      let date_to = null;
      if(this.search.dateRangeEnd){
        date_to = moment(this.search.dateRangeEnd).format('YYYY-MM-DD');
      }   
      if((this.search.query.length > 0 || this.search.address.length > 0 || this.search.dateRangeStart || this.search.dateRangeEnd) && (this.$route.fullPath == '/')){
        this.$router.push({name: 'search', query: { address: this.search.address, lat:this.search.lat, lng: this.search.lng, query: this.search.query, dateRangeStart: date_from, dateRangeEnd: date_to }});
      }else{
        let query = "pageSize=" + this.search.page + "&offset=0";
        if(this.search.query.length > 0) { query += "&query=" + this.search.query; }
        if(this.search.lat.length > 0) { query += "&latitude=" + this.search.lat; }
        if(this.search.lng.length > 0) { query += "&longitude=" + this.search.lng; }
        if(this.search.dateRangeStart) { query += "&dateFrom=" + date_from; }
        if(this.search.dateRangeEnd) { query += "&dateTo=" + date_to; }
        console.log(query);

        let loader = this.$loading.show({
            container: null,
            canCancel: true,
            onCancel: this.onCancel,
        });
                
        this.$store.dispatch('SEARCH', query).then((response) => {
          if(response){
            this.$emit('reloadData', response)
          }
          loader.hide();
        });
      }
    },
    exit(){
      this.$store.dispatch('LOGOUT');
      this.$router.push('/');
    },
    bindClick: function(event) {
      var ignoreClickOnMeElement = document.getElementById('search-query');
      if(ignoreClickOnMeElement){
        var isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
        if (!isClickInsideElement) {
          this.showResult = false;
        }else{
          this.showResult = true;
        }
      }
    },
    menuOpen(){
      document.querySelector(".overlay").classList.add("overlay_show");
      document.querySelector(".modal-menu").classList.add("modal-menu_show");
    },
    menuClose(){
      document.querySelector(".overlay").classList.remove("overlay_show");
      document.querySelector(".modal-menu").classList.remove("modal-menu_show");
    },
    setKeyWord(name){
      this.search.query = name;
    }
  }
}
</script>