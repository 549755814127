<template>
  <div class='auth-block'>
    <h1 class="auth-title">Восстановление пароля</h1>
    <p>Пожалуйста введите новый пароль</p>
    <form @submit="recover">
      <div class="row pt-4">
        <div class="col">
          <strong>Пароль</strong>
          <input type="password" v-model="pass" class="form-control" required='true' placeholder="Введите пароль">
        </div>
      </div>
      <div class="row pt-4">
        <div class="col">
          <strong>Повторение пароля</strong>
          <input type="password" v-model="confirmPass" class="form-control" required='true' placeholder="Введите пароль">
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button type='submit' class="btn btn-success mt-4 color-btn-green">Изменить</button>
          <social></social>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        pass: '',
        confirmPass: ''
      }
    },
    methods: {
      recover(e) {
        e.preventDefault();
        if(this.pass != this.confirmPass){
          alert("Пароли не совпадают.");
          return;
        }
        this.$store.dispatch('RECOVERED_PASSWORD_SEND', { password: this.pass, token: this.$route.query.token }).then((response) => {
          alert("Пароль успешно изменен.");
          }, reason => {
          alert("При изменение пароля произошла ошибка.");
        });
      }
    }
  }
</script>