
const user = {
  userId: undefined,
  username: undefined,
  lastName: '',
  firstName: '',
  googleId: '',
  vkId: '',
  image: {},
  fbId: '',
  roles: [],
  enabled: false,
  avatar: '',
  gender: '',
};

export { user };