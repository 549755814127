<template>
  <div id="googleMap" style="width:100%;height:400px;"></div>
</template>

<style>
  .map{
    width: 100%; 
    height: 500px
  }
</style>

<script>
  export default {
    name: 'map',
    data() {
      return{
        centerCoord: { lat: 40.689247, lng: -74.044502 },
      }    
    },
    props: {
      advice: {
        type: Array,
        default(){
          return []
        }
      },
      center: {
        type: Object,
        default(){
          return {}
        }
      }
    },
    mounted() {
      let coords = [];
      coords.push({ latitude: this.center.lat, longitude: this.center.lng, title: this.center.title, desc: this.center.desc });
      if(this.advice && this.advice.length > 0){
        this.advice.forEach((element) => {
          if(element.title.length > 0 && element.latitude != null && element.longitude != null){
            coords.push({ latitude: element.latitude, longitude: element.longitude, title: element.title, desc:element.descriptionHtml });
          }
        });
        coords.concat(this.advice);
      }
      this.initMap(coords);
    },
    methods: {
      initMap(coords) {
       var mapProp= {
          center:new google.maps.LatLng(40.689247,-74.044502),
          zoom:8,
        };
        var map = new google.maps.Map(document.getElementById("googleMap"),mapProp);
        var bounds = new google.maps.LatLngBounds();

        var image_info = {
            url: require('@/assets/img/icon-info.svg'),
            scaledSize: new google.maps.Size(30, 30),
            origin: new google.maps.Point(0,0),
            anchor: new google.maps.Point(0, 0)
        };

        var image_main = {
            url: require('@/assets/img/dificulty.svg'),
            scaledSize: new google.maps.Size(50, 50),
            origin: new google.maps.Point(0,0),
            anchor: new google.maps.Point(0, 0)
        };

        var infoWindow = new google.maps.InfoWindow();

        for (var i = 0; i < coords.length; i++) {
          var myLatLng = new google.maps.LatLng(coords[i].latitude, coords[i].longitude);
          var data = coords[i];
          var marker = new google.maps.Marker({
            position: myLatLng,
            map: map,
            icon:(i == 0) ? image_main : image_info,
            title: coords[i].title
          });

          (function (marker, data) {
            google.maps.event.addListener(marker, "click", function (e) {
              infoWindow.setContent("<div style = 'max-width:700px;min-height:20px'><strong >" + data.title + "</strong><p style='padding-top:5px'>" + data.desc + "</p></div>");
              infoWindow.open(map, marker);
            });
            })(marker, data);

          bounds.extend(myLatLng);
        }
        map.fitBounds(bounds);       
      },
    },
  }
</script>