<template>
  <div class="event-header" :style="{'background-image': 'url(' + require('@/assets/img/main-img.jpg') + ')'}">
    <nav-header></nav-header>
    <div class="">
      <div class="ambasador-avatar" v-if='user.firstName'>
        <img :src="user_avatar()" alt="">
        <strong class="text-center user-avatar-desc text-white" style='font-size:38px;'>{{user.firstName}} {{user.lastName}}</strong>
        <p class="text-white text-center">{{user.location}}</p>
        <div class="container mt-3">
          <div class="row justify-content-md-center" style='margin: auto;' v-if="user.socials">
            <div class="col-xs-auto block-soc-icon" style='width: 60px;' v-for="soc in user.socials" v-bind:key="soc.id">
              <a class="soc-icon" target='blank' :href="soc.value">{{soc.title}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="center-block mb-0" v-if="user.description || user.achievements">
    <div class="row m-0">
      <div class="col-xl-2"></div>
      <div class="col-xl-8 m-0 p-0">
        <div class="event-desc-block" v-if="user.description">
          <h4 class="page-title pt-4">
            Обо мне
          </h4>
          <p class="ambasador-desc" align="justify">{{user.description}}</p>
        </div>
        <div class="event-desc-block" v-if="user.achievements">
          <h4 class="page-title pt-4">
            Мои достижения
          </h4>

          <ul style="list-style-type: none; padding-left:55px;">
            <li v-for="achiv in user.achievements" v-bind:key="achiv">
              <strong>{{achiv.title}}</strong>
              <p class="pt-2">{{achiv.description}}</p>
              <small>{{ achiv.location }} ({{ achiv.year }} год)</small>
              <br>
              <small>Место - {{achiv.result}}</small>
            </li>
          </ul>
          
        </div>
      </div>
      <div class="col-xl-2"></div>
    </div>
  </div>


  <div v-for="item in data.content" v-bind:key="item.id">
    <div class="center-block">
      <div class="row m-0">
        <div class="col-xl-2"></div>
        <div class="col-xl-8 m-0 p-0">

          <div class="event-desc-block">
            <h4 class="page-title">
              Занятия со мной
            </h4>
            <p class="ambasador-desc pb-4" align="justify" v-html="item.descriptionHtml"></p>
          </div>

          <div class="event-desc-block">
            <h4 class="page-title">
              Дополнительная информация
            </h4>
            <ul class="ambasador-info">
              <li>
                <strong>Продолжительность: </strong> {{item.len}}
              </li>
              <li>
                <strong>Количество: </strong> {{item.cnt}}
              </li>
              <li>
                <strong>Местоположение: </strong>{{item.place}}
              </li>
              <li>
                <strong>Цена: </strong> {{item.cost}}
              </li>
            </ul>
            <a :href=link(item.descriptionHtml) id='buy' style='margin: 0 55px; margin-top: 10px; width:300px' class="btn btn-success">Купить</a>
          </div>

        </div>
        <div class="col-xl-2"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .event-header{
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    height: 606px;
    .navbar {
      background: transparent !important;
      top: 75px;
    }
  } 
  .ambasador-avatar{
    margin: auto;
    display: block;
    margin-top: 150px; 
    img{
      -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 1);
      -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 1);
      box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 1);
      width: 100%;
      border-radius: 50%; 
      max-width:150px;
      margin: auto;
      display: block;
    }
  }

  .ambasador-desc{
    padding: 0 55px;
  }
  
  .ambasador-info{
    list-style-type: none;
    padding: 0 55px;
  }

  .soc-icon{
    color:white;
    background: #0eaafd;
    padding: 7px;
    border-radius: 50px;
  }


  @media (max-width: 599px){
    #buy{
      width: 200px !important;
    }
    .page-title{
      font-size: 30px;
    }
    .block-soc-icon{
      margin: auto;
    }
  }
</style>

<script>
import NavHeader from '@/pages/layout/nav'

export default {
  data(){
    return {
      data: {},
      user: {}
    }
  },
  components:{  NavHeader  },
  mounted() {
    this.$store.dispatch('GET_AMBASADOR_BY_ID', this.$route.params.id).then((response) => {
      this.data = response;
    });
    this.$store.dispatch('GET_USER_BY_ID', this.$route.params.id).then((response) => {
      this.user = response;
    });
  },
  methods:{
    user_avatar(){
      if(this.user && this.user.image){
        return this.$store.getters.MAIN_URL + this.user.image.assets['160x160'];
      }
      return require("@/assets/img/img-ava2.jpg");
    },
    link(linkHtml){
      if(linkHtml && linkHtml.length > 0){
        return linkHtml.replace("<p>", '').replace("</p>", '');
      }
    }
  }
}
</script>
