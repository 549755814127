<template>
  <div class="center-block" v-if="data.title">
    <h1 class="publication-title pt-4 pb-4">{{ data.title }}</h1>
    <small class="publication-date">{{ data.createdDate.substr(0,10) }}</small>
    <h4 class="page-title pt-4">
      Общая информация
    </h4>
    <p class="publication-desc pb-4" v-html="data.descriptionHtml" align="justify"></p>
    <h4 class="page-title pt-4">
      Медиа информация
    </h4>
    <div class="container publication-images">
      <swiper navigation :pagination="{ clickable: true }">
        <swiper-slide v-for="image in images" v-bind:key="image.id">
          <img class="publication-image" style='height: 100%; width: 100%; object-fit: contain' :src="first_image_url(image)" alt="">
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<style>
  .publication-desc, .publication-title, .publication-date{
    padding: 0 55px;
  }
  .publication-images{
    padding-bottom: 80px;
    padding-top: 20px;
    max-width: 700px !important;
  }
  .publication-image{
    display: block;
    margin: auto;
    height: 650px;
  }
</style>

<script>
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default {
  data(){
    return {
      id: this.$route.params.id,
      data: { },
      images: [],
    }
  },
  components:{ Swiper, SwiperSlide, Navigation  },
  mounted() {
    this.$store.dispatch('GET_PUBLICATION_BY_ID', this.$route.params.id).then((response) => {
      this.data = response;
      this.images = this.data.images
    });
  }
}
</script>