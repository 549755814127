<template>
  <div class="center-block">
    <h2 class="page-title">
      Помощь и поддержка
    </h2>
    <p class="help-desc">Здесь Вы можете найти ответы на часто задаваемые вопросы. Если Вы не нашли то, что искали, пожалуйста, напишите нам на почту <a href="mailto:teamwayver@gmail.com">teamwayver@gmail.com</a></p>
    <div class="answer pt-4">
      <div class="answer__content">
        <div class="row">
          <div class="col pt-2">
            <div class="answer__item">
              <div class="answer__item_head">
                <span>Пользователи</span>
              </div>
              <div class="answer__item_content">
                <ul>
                  <li>
                    <a href="#">Зачем нужен личный кабинет на сайте?</a>
                  </li>
                  <li>
                    <a href="#">Как создать личный кабинет на сайте?</a>
                  </li>
                  <li>
                    <a href="#">Как зарегистрироваться на событие?</a>
                  </li>
                  <li>
                    <a href="#">Как я могу изменить адрес электронной почты?</a>
                  </li>
                  <li>
                    <a href="#">Как зарегистрировать участника через свой Личный кабинет?</a>
                  </li>
                  <li>
                    <a href="#">Как удалить профиль?</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col pt-2">
            <div class="answer__item">
              <div class="answer__item_head">
                <span>Партнеры</span>
              </div>
              <div class="answer__item_content">
                <ul>
                  <li>
                    <a href="#">Зачем нужен личный кабинет на сайте?</a>
                  </li>
                  <li>
                    <a href="#">Как создать личный кабинет на сайте?</a>
                  </li>
                  <li>
                    <a href="#">Как зарегистрироваться на событие?</a>
                  </li>
                  <li>
                    <a href="#">Как я могу изменить адрес электронной почты?</a>
                  </li>
                  <li>
                    <a href="#">Как зарегистрировать участника через свой Личный кабинет?</a>
                  </li>
                  <li>
                    <a href="#">Как удалить профиль?</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .help-desc{
    padding: 0 55px;
  }
  .answer__item_content ul{
    list-style-type: none;
    padding-left: 0;
  }
  .answer__item_content ul li{
    border-bottom: 1px solid gray;
  }
  .answer__item_content{
    font-size: 13px;
  }
</style>