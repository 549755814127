<template>
  <li @click="goToPublication()">
    <small>{{news_data.createdDate.substr(0,10)}}</small>
    <br>
    <a href="#">{{news_data.title}}</a>
  </li>
</template>

<script>
export default {
  name: "news",
  props: {
    news_data: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  methods:{
    goToPublication(){
      this.$router.push({name: 'publication', params: { id: this.news_data.id }});
    }
  }
}
</script>