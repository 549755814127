<template>
  <search @reloadData="reloadNews"></search>
  <div class="p-4">
    <div class="search-items">
      <div class="row">
        <div class="col-md-4 col-xs-12 pt-2" v-for="item in findList" v-bind:key="item.id">
          <a :href="'/'+ item.objectType +'/' + item.id">
            <div class="top-events__item_head">
              <img :src="image_url(item.images)" alt="">
              <div class="top-events__item_head-top">
                <span>{{ item.country }}</span>
              </div>
              <div class="top-events__item_head-bottom">
                <div>
                  <strong class="title">{{item.title}}</strong>
                </div>
              </div>
            </div>

            <div>
              <div class="top-events__header">
                <a href="#">by {{ item.authorFirstName }} {{ item.authorLastName }}</a>
              </div>
              <div class="top-events__text" v-html="desc(item.description)"></div>
              <!--
                <p class="search-event">{{eventType(item.eventType)}}</p>
              -->
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .search-items .container {
    border: 1px solid gray;
    padding: 20px 20px 0px;
    margin-bottom: 1rem;
    &__image {
      margin: 30px 30px 30px 0;
      float: left;
    }
    &__text {
      display: inline;
    }
  }
  .search-items{
    padding-bottom: 30px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 1215px;
  }

  .search-item:hover{
    -webkit-box-shadow: 0 0 10px -5px #0eaafd;
    box-shadow: 0 0 10px -5px #0eaafd;
    cursor: pointer;
  }
  .search-decs{
    padding-top: 10px;
    font-size: 13px;
  }
  .search-event{
    padding-top: 15px;
    font-weight: bold;
    font-size: 13px;
    padding-bottom: 10px;
  }
</style>

<script>
import search from '@/components/search'

export default {
  data() {
    return {
      findList: []
    };
  },
  components:{ search },
  methods:{
    reloadNews(data){
      this.findList = data;
    },
    eventType(data){
      if(data && data.length > 0){
        let text = '';
        data.forEach((element) => {
          if(text.length > 0) { text += ' , '; }
          text += element;
        });
        return text;
      }
    },
    desc(data){
      if(data){
        data = JSON.parse(data); 
        if(data.blocks && data.blocks.length > 0){
          let text = data.blocks[0].text
          if(text.length > 150){
            text = text.substr(0, 200) + "...";
          }
          return text;
        }
        return '';
      }
    }
  }
}
</script>