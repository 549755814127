<template>
  <nav aria-label="Page navigation">
    <ul class="pagination">
      <li class="page-item" @click="prev()">
        <a class="page-link" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li class="page-item" v-bind:class="{ active: (n === currentPage) }" @click="page(n)" v-for="n in pageSize" v-bind:key="n">
        <a class="page-link">{{n}}</a>
      </li>
      <li class="page-item" @click="next()">
        <a class="page-link" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<style scoped>
  .page-item.active .page-link{
    background-color: #5362f6;
    border-color: #0d6efd;
  }
</style>

<script>

export default({
  name: "pagination",
  props: {
    pageSize: {
      type: Number,
      default: 0
    }
  },
  data(){
    return {
      currentPage: 1
    }
  },
  methods:{
    prev(){
      if(this.currentPage >= 2) { this.currentPage -= 1; this.reload(); }
    },
    next(){
      if(this.pageSize > this.pageActive) { this.currentPage += 1; this.reload(); }
    },
    page(index){
      this.currentPage = index;
      this.reload();
    },
    reload(){
      this.$emit('reloadData', this.currentPage)
    }
  }
})
</script>
