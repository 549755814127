<template>
  <page-header></page-header>
  <div class="app">
    <router-view></router-view>
  </div>
  <page-footer></page-footer>
</template>

<script>
import PageFooter from '@/pages/layout/footer'
import PageHeader from '@/pages/layout/header'

export default {
  components: { PageFooter, PageHeader },
  created: function () {
    this.$store.dispatch('CHECK_LOGIN');
  }
}
</script>