<template>
  <div class='auth-block'>
    <h1 class="auth-title">Зарегистрироваться</h1>
    <p>У вас есть аккаунт? <a href='/login' class="color-blue">Войдите</a></p>
    <form @submit="reg">
      <div class="row pt-4">
        <div class="col">
          <strong>Имя</strong>
          <input type="text" v-model="user.firstName" class="form-control" placeholder="Введите имя" required='true'>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col">
          <strong>Фамилия</strong>
          <input type="text" v-model="user.lastName" class="form-control" placeholder="Введите фамилию">
        </div>
      </div>
      <div class="row pt-4">
        <div class="col">
          <strong>Email</strong>
          <input type="email" v-model="user.email" class="form-control" placeholder="Введите email" required='true'>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col">
          <strong>Пароль</strong>
          <input type="password" v-model="user.pass" class="form-control" placeholder="Введите пароль" required='true'>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col">
          <strong>Повторите пароль</strong>
          <input type="password" v-model="user.confirmPass" class="form-control" placeholder="Повторить пароль" required='true'>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="regCond" checked>
            <label class="form-check-label" for="regCond">
              Я принимаю условия пользовательского соглашения
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button type='submit' class="btn btn-success mt-4 color-btn-green">Присоединиться</button>
          <social></social>
        </div>
      </div>
    </form>
  </div>
</template>

<style>
  .auth-block{
    margin: 0 auto 80px;
    max-width: 800px;
    padding-top: 20px;
    padding: 0 55px;
  }
  .auth-title{
    color: #5362f6;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 25px;
    padding-top:60px;
  }
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    background: transparent;
    border: none;
    border-bottom: 1px solid #000000;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
  }
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus  {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
</style>

<script>
  import Social from '@/pages/layout/social'
  export default {
    data(){
      return {
        user: {}
      }
    },
    components: { Social },
    methods: {
      reg(e) {
        e.preventDefault();
        if(this.user.pass != this.user.confirmPass){
          alert("Пароли не совпадают.");
          return;
        }
        this.$store.dispatch('REG_NEW_USER', this.user).then((response) => {
          this.$router.push('/');
        }, reason => {
          alert("Не удалось зарегистрировать пользователя.");
        });
      },
      bindClick: function(event) {
        if (event.keyCode === 13) {
          this.reg();
        }
      },
    },
    created: function() {
      document.addEventListener('keyup', this.bindClick);
    },
    beforeDestroy: function() {
      document.removeEventListener('keyup', this.bindClick);
    },
  }
</script>