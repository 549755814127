<template>
  <div class='auth-block'>
    <h1 class="auth-title">Войти</h1>
    <p>У вас нет аккаунта? <a href='/reg' class="color-blue">Создайте свой аккаунт</a>, это займет меньше минуты. <a href='#' data-bs-toggle="modal" data-bs-target="#emailModal" class="color-blue">Забыли пароль?</a></p>
    <form @submit="login">
      <div class="row pt-4">
        <div class="col">
          <strong>Email</strong>
          <input type="email"  v-model="email" class="form-control" required='true' placeholder="Введите email">
        </div>
      </div>
      <div class="row pt-4">
        <div class="col">
          <strong>Пароль</strong>
          <input type="password" v-model="pass" class="form-control" required='true' placeholder="Введите пароль">
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button type='submit' class="btn btn-success mt-4 color-btn-green">Войти</button>
          <social></social>
        </div>
      </div>
    </form>
  </div>
 
  <!-- Email -->
  <div class="modal fade" id="emailModal" tabindex="-1" aria-labelledby="emailModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <form @submit="recoverPassword">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="emailModalLabel">Восстановление пароля</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-4">
            <strong>EMAIL</strong>
            <input type="email" v-model="recoverEmail" class="form-control" required='true' placeholder="Введите email">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
            <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Продолжить</button>
          </div>
        </div>
      </form>
    </div>
  </div>

</template>

<style>
  .auth-block{
    margin: 0 auto 80px;
    max-width: 800px;
    padding-top: 20px;
    padding: 0 55px;
  }
  .auth-title{
    color: #5362f6;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 25px;
    padding-top:60px;
  }
  input[type="email"],input[type="password"] {
    background: transparent;
    border: none;
    border-bottom: 1px solid #000000;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
  }
  input[type="email"]:focus,input[type="password"]:focus  {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .icon{
    float: right;
    padding-top: 20px;
  }
  .icon img{
    margin-right: 10px;
  }
</style>

<script>
  import Social from '@/pages/layout/social'
  export default {
    data(){
      return{
        email:'',
        pass: '',
        recoverEmail: ''
      }
    },
    components: { Social },
    methods: {
      login(e) {
        e.preventDefault();
        this.$store.dispatch('LOGIN', { email: this.email, password: this.pass }).then((response) => {
            this.$router.push('/');
          }, reason => {
          alert("Не удалось выполнить вход.");
        });
      },
      recoverPassword(e){
        e.preventDefault();
        this.$store.dispatch('RECOVER_PASSWORD', this.recoverEmail).then((response) => {
            alert("На вашу почту отправлено письмо с дальнейшими инструкциями.");
          }, reason => {
          alert("Не удалось отправить запрос.");
        });
      }
    }
  }
</script>