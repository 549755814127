<template>
  <search></search>
  <section class="events">
    <div class="events__head">
      <h2>
        Ближайшие события
      </h2>
    </div>
    <div class="events__slider">
      <div class="events__control">
        <button class="events__control_prev events__control_arrow prevArrow" >
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 447.243 447.243" style="enable-background:new 0 0 447.243 447.243;"
            xml:space="preserve">
            <path d="M420.361,192.229c-1.83-0.297-3.682-0.434-5.535-0.41H99.305l6.88-3.2c6.725-3.183,12.843-7.515,18.08-12.8l88.48-88.48
              c11.653-11.124,13.611-29.019,4.64-42.4c-10.441-14.259-30.464-17.355-44.724-6.914c-1.152,0.844-2.247,1.764-3.276,2.754
              l-160,160C-3.119,213.269-3.13,233.53,9.36,246.034c0.008,0.008,0.017,0.017,0.025,0.025l160,160
              c12.514,12.479,32.775,12.451,45.255-0.063c0.982-0.985,1.899-2.033,2.745-3.137c8.971-13.381,7.013-31.276-4.64-42.4
              l-88.32-88.64c-4.695-4.7-10.093-8.641-16-11.68l-9.6-4.32h314.24c16.347,0.607,30.689-10.812,33.76-26.88
              C449.654,211.494,437.806,195.059,420.361,192.229z" />
          </svg>
        </button>

        <button class="events__control_next events__control_arrow nextArrow">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 492.004 492.004" style="enable-background:new 0 0 492.004 492.004;"
            xml:space="preserve">
            <path d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136
              c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002
              v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864
              c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872
              l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z" />
          </svg>
        </button>
      </div>
  
      <swiper :breakpoints="breakpoints" :slidesPerView=3 :navigation="{ nextEl: '.nextArrow', prevEl: '.prevArrow' }">
        <swiper-slide v-for="topevent in topEventList" v-bind:key="topevent.id">
          <a :href="'/event/' + topevent.id" class="events__inner_slide swiper-slide">
            <img class="event-image" :src="image_url(topevent.images, '640x480')" alt="">
            <div class="events__inner_slide-desc">
              <div class="events__inner_slide-head">
                {{topevent.title.substr(0,30)}}
                <div class="mini-line"></div>
                <span>{{ topevent.town }}, {{ topevent.country }}</span>
              </div>
            </div>

            <!--
            <div class="events__inner_slide-desc">
              <div class="events__inner_slide-head">
                {{topevent.title.substr(0,30)}}
                <span>{{ topevent.country }}</span>
              </div>
              <div class="events__inner_slide-date">
                {{ startEndDate(topevent.startDateLocal,topevent.endDateLocal) }}<br>
                {{topevent.endDateLocal.substr(0,4)}}
              </div>
            </div>
            -->
          </a>
        </swiper-slide>
      </swiper>
    </div>
  </section>

  <section class="top-events" ref="events">
    <h2>
      Топ мировых событий
    </h2>
    <div class="row m-0">
      <div class="col-xl-9 pt-2">
        <div class="top-events__content">
          <div class="top-events__wrap">
            <div class="top-events__events">
              <event-card v-for="event in eventsList" :key="event.id" :event_data="event"></event-card>
            </div>
          </div>
        </div>
        <pagination :pageSize="eventPageSize" @reloadData="reloadEvents"></pagination>
      </div>
      <div class="col-xl-3 pt-2">
        <aside class="top-events__filter">
          <div class="top-events__filter_wrap">
            <strong class="color-blue">Новости</strong>
            <ul class="publication-list">
              <news v-for="pub in newsList" v-bind:key="pub.id" :news_data="pub"></news>           
            </ul>
          </div>
        </aside>
      </div>
    </div>
  </section>

  <img src="@/assets/img/discount.jpg" alt="" style="width:100%">
  <!--
  <section class="banner">
    <div class="banner__img2" :style="{'background-image': 'url(' + require('@/assets/img/discount.jpg') + ')'}"></div>
  </section>-->
  <!--
  <section class="banner">
    <div class="banner__img" :style="{'background-image': 'url(' + require('@/assets/img/banner.jpg') + ')'}"></div>
    <div class="banner__content">
      <h3 class="banner__head">
        Туры на<br>летние каникулы
      </h3>
      <div class="banner__desc">
        Скидка <strong>-30%</strong>
      </div>
    </div>
  </section>
  -->

  <section class="top-events" style="margin-top: 60px;">
    <h2 style='padding-left:0 !important;'>
      Прошедшие события
    </h2>
    <div class="row m-0">
      <div class="col pt-2">
        <div class="top-events__content">
          <div class="top-events__wrap">
            <div class="top-events__events">
              <event-card v-for="event in oldeventsList" :key="event.id" :event_data="event"></event-card>
            </div>
          </div>
        </div>
        <pagination :pageSize="oldeventPageSize" @reloadData="reloadOldEvents"></pagination>
      </div>
    </div>
  </section>

  <section class="about">
    <h2>
      О проекте
    </h2>
    <img src="@/assets/img/about.jpg" alt="" >
    <div class="about__content">
      <div class="about__content_head">
        О нас
      </div>
      <div class="about__content_text">
        Wayver – это on-line сообщество для людей, которые открывают для себя новые направления жизни, расширяют границы своих возможностей и практикуют активный и здоровый образ жизни. Миллионы людей каждый день ищут информацию о том или ином спортивном событии, эта информация разрозненна и зачастую не объективна, Wayver поможет получить системную и полезную информацию о любом спортивном событии будь то марафон, забег на 10 км, лыжные гонки или иной событийный туризм. Вы сможете зарегистрироваться на любое интересное для Вас событие, получить исчерпывающую информацию, взаимодействовать с другими людьми, которые имеют схожие интересы, получить советы по питанию и подготовки от опытных специалистов, подобрать для себя самый удобный маршрут и размещение.
      </div>
    </div>
  </section>

  <section class="answer">
    <h2>
      Ответы<br> на часто задаваемые вопросы
    </h2>
    <div class="answer__content">
      <div class="row">
        <div class="col pt-2">
          <div class="answer__item">
            <div class="answer__item_head">
              <span>1. Как заказать авиаперелет?</span>
            </div>
            <div class="answer__item_content">
              <p>
                Крутая трасса для настоящих любителей длинных
                дистанций в гористой местности Рекомендую всем !
                Это что-то с чем-то, еле добежал,
                но после финиша Это что-то с чем-то, еле добежал,
                Это что-то с чем-то, еле добежал, мендую всем !
                Это что-то с чем-то, еле добежал,мендую всем !
                Это что-то с чем-то, еле добежал,
              </p>
            </div>
          </div>
        </div>
        <div class="col pt-2">
          <div class="answer__item">
            <div class="answer__item_head">
              <span>2. Как заказать авиаперелет?</span>
            </div>
            <div class="answer__item_content">
              <p>
                Крутая трасса для настоящих любителей длинных
                дистанций в гористой местности Рекомендую всем !
                Это что-то с чем-то, еле добежал,
                но после финиша Это что-то с чем-то, еле добежал,
                Это что-то с чем-то, еле добежал, мендую всем !
                Это что-то с чем-то, еле добежал,мендую всем !
                Это что-то с чем-то, еле добежал,
              </p>
            </div>
          </div>
        </div>
        <div class="col pt-2">
          <div class="answer__item">
            <div class="answer__item_head">
              <span>3. Как заказать авиаперелет?</span>
            </div>
            <div class="answer__item_content">
              <p>
                Крутая трасса для настоящих любителей длинных
                дистанций в гористой местности Рекомендую всем !
                Это что-то с чем-то, еле добежал,
                но после финиша Это что-то с чем-то, еле добежал,
                Это что-то с чем-то, еле добежал, мендую всем !
                Это что-то с чем-то, еле добежал,мендую всем !
                Это что-то с чем-то, еле добежал,
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<style scoped>
 .swiper-slide{
   /*max-width: 255px;*/
   height: 360px;
   /*margin-right: 15px !important;*/
  }

  /*
  @media (max-width: 640px) {
    .swiper-slide{
      margin: auto;
      max-width: 100% !important;
      width: 100% !important;
      margin-right: 0px !important;
    }
    .events__inner_slide{
     width: 100% !important;
    }
  }*/

</style>

<script>
import eventCard from '@/components/eventCard'
import pagination from '@/components/pagination'
import search from '@/components/search'
import news from '@/components/news'

import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
  data() {
    return {
      eventsList: [],
      oldeventsList: [],
      topEventList: [],
      newsList: [],
      eventPageSize: 0,
      oldeventPageSize: 0,
      breakpoints:{
        220: {
          slidesPerView: 1,
          spaceBetween: 20,
          slidesPerGroup: 1,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
          slidesPerGroup: 1,
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 40,
          slidesPerGroup: 1,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 50,
          slidesPerGroup: 3,
        },
      }
    };
  },
  components:{
    eventCard, pagination, search, news, Swiper, SwiperSlide
  },
  mounted() {
    this.events(1);
    this.old_events(1);
    this.publication();
  },
  methods:{
    reloadEvents(page){
      this.events(page);
      var element = this.$refs['events'];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    reloadOldEvents(page){
      this.old_events(page);
    },
    events(page){
      this.$store.dispatch('GET_EVENTS', page).then((response) => {
        this.eventsList = response.content;
        if(page == 1 && this.topEventList.length == 0){
          this.topEventList = response.content;
        }
        this.eventPageSize = response.totalPages;
      });
    },
    old_events(page){
      this.$store.dispatch('GET_OLD_EVENTS', page).then((response) => {
        this.oldeventsList = response.content;
        this.oldeventPageSize = response.totalPages;
      });
    },
    publication(){
      this.$store.dispatch('GET_PUBLICATION').then((response) => {
        this.newsList = response.content;
      });
    },
    startEndDate(date1,date2){
      let date_start_arr = date1.split('-');
      let date_end_arr = date2.split('-');
      return date_start_arr[2] + " — " + date_end_arr[2] + " " + this.months[parseInt(date_end_arr[1]) - 1];
    }
  }
}
</script>