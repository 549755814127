import { createStore } from 'vuex';
import axios from 'axios'
import { user } from './user';
import {setCookie, deleteCookie, getCookie} from "@/services/cookies";

export default createStore({
  state:{
    isAuth: true,
    currentUser: user,
    main_url: 'https://wayver.ru'//http://wayver.work //https://wayver.ru
  },
  mutations: {
    SET_CURRENT_USER(state, payload){
      let avatar = require("@/assets/img/img-ava2.jpg");
      if(payload.image && payload.image.attachment){
        avatar = state.main_url + payload.image.assets['80x80'];
      }

      state.currentUser = {
        userId: payload.id,
        username: payload.username,
        lastName: payload.lastName,
        firstName: payload.firstName,
        googleId: payload.googleId,
        vkId: payload.vkId,
        fbId: payload.fbId,
        gender: payload.gender,
        image: payload.image,
        birthday: payload.bd,
        location: payload.location,
        eventType: payload.eventType,
        family: payload.family || [],
        socials: payload.socials || [],
        background: payload.background || {},
        achievements: payload.achievements || [],
        description: payload.description,
        avatar: avatar
      };
      state.isAuth = true;
    },
    USER_STATE(state, is_auth){
      state.isAuth = is_auth;
      if(is_auth == false){
        state.currentUser = null;
        deleteCookie('AUTHORIZATION', '/');
        deleteCookie('USER_ID', '/');
      }
    },
    SET_USER_DATA(state, payload){
      for (var key in payload) {
        state.currentUser[key] = payload[key];
      }
    },
    UPDATE_USER_AVATAR(state, image_id){
      state.currentUser.image = {
        attachment: image_id
      }
    },
  },
  actions:{
    SEARCH({commit}, query){
      return new Promise((resolve, reject) => {
        axios.get(this.state.main_url + "/v1/search?" + query, {}).then(response => {
          resolve(response.data);
        }).catch(function (error) {
          reject(error);
        });
      })
    },
    SEARCH_AUTOCOMPLETE({commit}, txt){
      return new Promise((resolve, reject) => {
        axios.get(this.state.main_url + "/v1/search/typeahead", {
          params:{
            query: txt,
            limit: 10
          }
        }).then(response => {
          resolve(response.data);
        }).catch(function (error) {
          reject(error);
        });
      })
    },
    REG_NEW_USER({commit, dispatch}, user){
      return new Promise((resolve, reject) => {
        let data = JSON.stringify({
          username: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          password: user.pass
        });
        axios.post(this.state.main_url + '/v1/user/register', data, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then(function (response) {
          dispatch('LOGIN', { email: user.email, password: user.pass });
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
      })
    },
    GET_CURRENT_USER({commit}, payload){
      axios.get(this.state.main_url + '/v1/user/' + payload.userId, {}).then(response => {
        commit('SET_CURRENT_USER', response.data);
      });
    },
    GET_USER_BY_ID({commit}, userId){
      return new Promise((resolve, reject) => {
        axios.get(this.state.main_url + '/v1/user/' + userId, {}).then(response => {
          resolve(response.data);
        });
      })
    },
    LOGOUT({commit}){
      commit('USER_STATE', false);
    },
    RECOVER_PASSWORD({commit}, email){
      return new Promise((resolve, reject) => {
        axios.post(this.state.main_url + '/v1/user/restorebyemail/' + email, {}, {
          headers: { 'Content-Type': 'application/json' }
        })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
      })
    },
    CHECK_LOGIN({commit, dispatch}){
      //setCookie('AUTHORIZATION', '9996a8c3-9289-4bc0-a3a1-e7191a15a89a', {expires: 3600 * 24 * 10, path: '/'});
      //setCookie('USER_ID', 8, {expires: 3600 * 24 * 10, path: '/'});
      const userId = getCookie('USER_ID');
      const token = getCookie('AUTHORIZATION');
      if (userId && token) {
        commit('USER_STATE', true);
        dispatch('GET_CURRENT_USER', { userId: userId })
      }else{
        commit('USER_STATE', false);
      }
    },
    LOGIN({commit, dispatch}, user){
      return new Promise((resolve, reject) => {
        let data = JSON.stringify({
          username: user.email,
          password: user.password
        });
        axios.post(this.state.main_url + '/v1/login/', data, {
          headers: { 'Content-Type': 'application/json' }
        })
        .then(function (response) {
          setCookie('AUTHORIZATION', response.data.token, {expires: 3600 * 24 * 10, path: '/'});
          setCookie('USER_ID', response.data.userId, {expires: 3600 * 24 * 10, path: '/'});

          commit('USER_STATE', true);
          dispatch('GET_CURRENT_USER', { userId: response.data.userId });
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
      })
    },
    SET_NEW_PASSWORD({commit}, pass){
      return new Promise((resolve, reject) => {
        let data = JSON.stringify({ password: pass });
        const token = getCookie('AUTHORIZATION');
        axios.post(this.state.main_url + '/v1/user/setNewPassword/' + token, data, {
          headers: { 'Content-Type': 'application/json' }
        }).then(response => {
          resolve(response.data);
        }).catch(function (error) {
          reject(error);
        });
      })
    },
    RECOVERED_PASSWORD_SEND({commit}, payload){
      return new Promise((resolve, reject) => {
        let data = JSON.stringify({ password: payload.password });
        axios.post(this.state.main_url + '/v1/user/setNewPassword/' + payload.token, data, {
          headers: { 'Content-Type': 'application/json' }
        }).then(response => {
          resolve(response.data);
        }).catch(function (error) {
          reject(error);
        });
      })
    },
    UPDATE_USER({commit}){
      return new Promise((resolve, reject) => {
        let data = JSON.stringify({
          username: this.state.currentUser.username,
          firstName: this.state.currentUser.firstName,
          lastName: this.state.currentUser.lastName,
          gender: null,
          bd: null,
          image: this.state.currentUser.image,
          googleId: '',
          vkId: '',
          fbId: '',
          location: null,
          eventType: [],
          family: [],
          socials: [],
          background: {},
          achievements: [],
          description: null
        });
        const userId = getCookie('USER_ID');
        axios.put(this.state.main_url + '/v1/user/personaldata/' + userId, data, {
          headers: { 'Content-Type': 'application/json' }
        }).then(response => {
          resolve(response.data);
        }).catch(function (error) {
          reject(error);
        });
      })
    },
    GET_EVENTS({commit}, page){
      return new Promise((resolve, reject) => {
        axios.get(this.state.main_url + "/v1/event/new", {
          params:{
            sort: "startDate,asc",
            status: "release",
            size: 10,
            page: page - 1
            //pageNumber: page
          }
        }).then(response => {
          resolve(response.data);
        }).catch(function (error) {
          reject(error);
        });
      })
    },
    GET_OLD_EVENTS({commit}, page){
      return new Promise((resolve, reject) => {
        axios.get(this.state.main_url + "/v1/event/old", {
          params:{
            sort: "startDate,asc",
            status: "release",
            size: 10,
            page: page - 1
            //pageNumber: page - 1
          }
        }).then(response => {
          resolve(response.data);
        }).catch(function (error) {
          reject(error);
        });
      })
    },
    GET_EVENT_BY_ID({commit}, id){
      return new Promise((resolve, reject) => {
        axios.get(this.state.main_url + "/v1/event/" + id).then(response => {
          resolve(response.data);
        }).catch(function (error) {
          reject(error);
        });
      })
    },
    GET_EVENT_LOTS_BY_ID({commit}, id){
      return new Promise((resolve, reject) => {
        axios.get(this.state.main_url + `/v1/event/${id}/lots`).then(response => {
          resolve(response.data);
        }).catch(function (error) {
          reject(error);
        });
      })
    },
    GET_AMBASADOR_BY_ID({commit}, id){
      return new Promise((resolve, reject) => {
        axios.get(this.state.main_url + "/v1/advertising/ambasabor?id=" + id).then(response => {
          resolve(response.data);
        }).catch(function (error) {
          reject(error);
        });
      })
    },
    GET_RELATED_EVENTSD({commit}, ids){
      return new Promise((resolve, reject) => {
        let events_arr = []
        for (let i = 0; i < ids.length; i++) {
          axios.get(this.state.main_url + "/v1/event/" + ids[i]).then(response => {
            events_arr.push(response.data);
            if(i == (ids.length - 1)){
              resolve(events_arr);
            }
          })   
          .catch((err) => {
            console.log('err', err);
          });
        }
      })
    },
    GET_PUBLICATION({commit}){
      return new Promise((resolve, reject) => {
        axios.get(this.state.main_url + "/v1/publication", {
          params:{
            type: "publication",
            status: "release",
            size: 10,
          }
        }).then(response => {
          resolve(response.data);
        }).catch(function (error) {
          reject(error);
        });
      })
    },
    GET_PUBLICATION_BY_ID({commit}, id){
      return new Promise((resolve, reject) => {
        axios.get(this.state.main_url + "/v1/publication/" + id).then(response => {
          resolve(response.data);
        }).catch(function (error) {
          reject(error);
        });
      })
    },
    UPLOAD_FILE({commit}, file){
      return new Promise((resolve, reject) => {
        var formData = new FormData();
        formData.append("file", file);
        axios.post(this.state.main_url + '/v1/attachment/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
          resolve(response.data);
        }).catch(function (error) {
          reject(error);
        });
      })
    },
    DOWNLOAD_FILE({commit}, id){
      return new Promise((resolve, reject) => {
        axios({
          url: this.state.main_url + "/v1/attachment/download/" + id,
          method: 'GET',
          responseType: 'blob', // important
        }).then(response => {
          resolve(new Blob([response.data]));
        }).catch(function (error) {
          reject(error);
        });
      })
    },
  },
  getters:{
    CURRENT_USER(state){
      return state.currentUser;
    },
    MAIN_URL(state){
      return state.main_url;
    },
    USER_AVATAR(state) {
      return state.currentUser.avatar;
    }
  }
})